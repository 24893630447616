import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Helmet from "react-helmet";
import Layout from 'components/Layout';
import BlogPost from 'components/pages/BlogPost';
import { Image } from 'components/pages/BlogPost/styled';
import { ImgGatsbyBackground } from 'components/wrappers';
import styled, { css } from 'styled-components';
import { blogPosts } from 'common/blogPostsFeatured';
import BlogBaseCard from 'components/blog/BaseCard';
import BlogSidebar from 'components/blog/BlogSidebar';
import {PostsWrapper} from 'components/pages/Blog/styled';
import { useIntl, Link, FormattedMessage } from "gatsby-plugin-intl";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from 'react-share';
import FacebookDefaultIcon from 'images/social-share-icons/facebook-white.png';
import TwitterDefaultIcon from 'images/social-share-icons/twitter-white.png';
import LinkedinDefaultIcon from 'images/social-share-icons/linkedin-white.png';
import BlogOptin from 'components/blog/BlogOptin';
import {Clear, BlogRow, ShareButton, ReadNow, DateText, TitleText, UnorderedList, OrderedList, FirstTitle, BottomShare, SectionFull, OptinSection, ColumnHalf, ColumnHalfLast} from 'components/blog/SingleBlogStyle';

import CommonLogisticsFeatured from 'images/featured-image/common-logistic-distribution.png';
import SelfDeliveryImg from 'images/blog-images/self-delivery.png';
import WarehouseImg from 'images/blog-images/warehouse.png';
import FBAImg from 'images/blog-images/FBA.png';

interface OwnProps {
  location: {
    href: string;
  };
}

const BlogArticle = ({ location }: OwnProps) => {
  const data = useStaticQuery(graphql`
    query CommonLogisticsMethodImg {
      file(relativePath: { eq: "self-delivery.png" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1400) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  const BackgroundImage = () => <ImgGatsbyBackground fluid={data.file.childImageSharp.fluid} alt="3 common logistic distribution methods"/>;
  const { href } = location;
  const intl = useIntl()
  return (
    <Layout>
      <Helmet 
        title={intl.formatMessage({ id: "blog-post-meta-title-3-common-logistics" })}
        htmlAttributes={{
          lang: 'en',
        }}
         meta={
          [
            {
              name: `description`,
              content: "",
            },
            {
              property: `og:title`,
              content: intl.formatMessage({ id: "blog-post-meta-title-3-common-logistics" }),
              // content: "3 Common Logistics Distribution Methods in Cross-border eCommerce",
            },
            {
              property: `og:description`,
              content: "",
            },
            {
              property: `og:image`,
              content: CommonLogisticsFeatured,
            },
            {
              property: `og:type`,
              content: `website`,
            },
            {
              name: `twitter:card`,
              content: `summary`,
            },
          ] as JSX.IntrinsicElements['meta'][]
        }
      />
      <BlogPost
        location={location}
        id="3-common-logistics-distribution-methods-in-cross-border-ecommerce"
        title="3 Common Logistics Distribution Methods in Cross-border eCommerce"
        date="2020-07-06"
        author="Joe Zhang"
        Background={BackgroundImage}
      >
        <BlogRow>
          <BlogSidebar/>
          <div className="column-half last">
            <p>
              With the rapid development of cross-border eCommerce, a variety of cross-border logistics models have also been born. The three main logistics models are self-delivery, overseas warehouses and Amazon FBA. As these three models are becoming more and more mature, their advantages and disadvantages arise. Shipkoo would like to give you a more clear insight of the three logistics methods which you could also choose which one fits you the most.
            </p>

            <h5>
              <strong>
                Self-delivery
              </strong>
            </h5>

            <p>
              It is the mainstream delivery method out of the others. It can grasp the two core "products" and "logistics" of cross-border e-commerce, and many small and medium-sized businesses will also prefer this model. However, its timeliness is hard to be controlled, and it is difficult to guarantee the product delivery time. This may lead to bad shipping experience for consumers. 
            </p>

            <p className="no-bottom">
              Advantage:
            </p>

            <UnorderedList className="no-top">
              <li>
                High flexibility and low storage fee
              </li>

              <li>
                No need to keep bulk inventory and deadstock
              </li>
            </UnorderedList>

            <p className="no-bottom">
              Disadvantages:
            </p>

            <UnorderedList className="no-top">
              <li>
                Low product ranking and low website exposure
              </li>

              <li>
                Hard to control the logistics timeliness
              </li>
            </UnorderedList>

            <p className="no-bottom">
              Summary:
            </p>
            <p>
              Although some people may think that logistics problems will easily lead to bad product reviews, Shipkoo’s Express Shipping Solution is a relatively stable shipping solution. We place test orders to confirm the actual shipping lead time of our existing routes regularly and also newly added shipping solutions to ensure what we promise to our customers. During the pandemic, a lot of packages are sent from China every day, so our special lines can quickly ship goods to destination countries without strict time limit requirements.
            </p>

            <Image src={SelfDeliveryImg} className="" alt="self delivery"/>

            <h5>
              <strong>
                Overseas warehouse
              </strong>
            </h5>

            <p>
              The overseas warehouse was once considered to be the best delivery method for cross-border eCommerce logistics in recent years. Its core competitiveness lies in the speed of delivery and localized services. It is generally aimed at large company and high-end product stores. Choose a suitable address in the place of sale to build the overseas warehouse. When consumers place orders, they ship directly from the overseas warehouse. The speed is fast and the timeliness is stable. However, this model requires a lot of capital and labor costs.
            </p>

            <p className="no-bottom">
              Advantage:
            </p>

            <UnorderedList className="no-top">
              <li>
                Large platform traffic 
              </li>

              <li>
                Fast and stable delivery
              </li>

              <li>
                Can expand the local market of the warehouse well
              </li>

              <li>
                Logistics costs will be much lower than FBA
              </li>
            </UnorderedList>

            <p className="no-bottom">
              Disadvantages:
            </p>

            <UnorderedList className="no-top">
              <li>
                Overstocking and increasing warehousing cost
              </li>

              <li>
                Difficulties in regulation and warehouse management
              </li>
            </UnorderedList>

            <p className="no-bottom">
              Summary:
            </p>
            <p>
              The advantages of overseas warehouses are still huge. They are more suitable for sellers who have already selected products and have their own brands. However, don’t worry if you are just doing a small business. Shipkoo is here to help! Fulfillment centers and bonded facilities in China, the UK, the US, Japan, Korea, and Vietnam means that you’re always close to a Shipkoo hub. Our Pick, pack and storage price is affordable which helps you to save time and money.
            </p>

            <Image src={WarehouseImg} alt="overseas warehouse"/>

            <h5>
              <strong>
                Amazon FBA
              </strong>
            </h5>

            <p>
              The full name of FBA is Fulfillment By Amazon. It is a service provided by Amazon that provides storage, packaging, and shipping assistance to sellers. This takes the burden off of sellers and grants them more flexibility in their selling practices. However, the cost is usually the highest among these three methods.
            </p>

            <p className="no-bottom">
              Advantage:
            </p>

            <UnorderedList className="no-top">
              <li>
                If you encounter a bad review from a buyer due to logistics reasons, Amazon will help delete it.
              </li>

              <li>
                Selecting Amazon FBA can also improve the listing ranking, help sellers become featured sellers and snatch shopping carts, increase customer trust and increase sales.
              </li>

              <li>
                Fast delivery time (most warehouses are close to the airport).
              </li>
            </UnorderedList>

            <p className="no-bottom">
              Disadvantages:
            </p>

            <UnorderedList className="no-top">
              <li>
                The overall cost is high
              </li>

              <li>
                Poor flexibility and cumbersome operations (buyer's return rate tends to rise, thereby increasing the cost of suppressing goods. If the seller is doing US FBA, the return address will only support the US)
              </li>

              <li>
                FBA warehouse will not provide customs clearance service for the seller's first shipment.
              </li>
            </UnorderedList>

            <p className="no-bottom">
              Summary:
            </p>
            <p>
              With complicated procedures and high fees, many online sellers still choose FBA because it helps sellers to maintain a good user shopping experience, and they can get support from the Amazon platform. With Shipkoo, we take your shipments from the factory and prepare them to fit Amazon’s standards. Shipkoo also palletizers your package, handles custom clearance, and air freights it to the destination country, and then delivers the shipments to a designated Amazon FBA warehouse.
            </p>

            <Image src={FBAImg} alt="amazon fba" />
            
            <BottomShare>
              <div className="column-half">
                <p>Share the article</p>
              </div>
              <div className="column-half last">
                <FacebookShareButton url={href}>
                  <ShareButton><img src={FacebookDefaultIcon} alt="Facebook Default Icon" /> <span>Share</span></ShareButton>
                </FacebookShareButton>
                <TwitterShareButton url={href}>
                  <ShareButton><img src={TwitterDefaultIcon} alt="Twitter Default Icon" /> <span>Tweet</span></ShareButton>
                </TwitterShareButton>
                <LinkedinShareButton url={href}>
                  <ShareButton><img src={LinkedinDefaultIcon} alt="Linkedin Default Icon" /> <span>Share</span></ShareButton>
                </LinkedinShareButton>
              </div>
              <Clear></Clear>
            </BottomShare> 
          </div>  
          <Clear></Clear>
       </BlogRow> 
      </BlogPost>
      <SectionFull>
        <BlogRow>
          <h2>More From Shipkoo</h2>
          <PostsWrapper>
            {blogPosts.map(post => (
              <BlogBaseCard key={`${post.title}-${post.id}`} post={post} />
            ))}
          </PostsWrapper>
        </BlogRow>
      </SectionFull>
      <BlogOptin />
    </Layout>
  );
};

export default BlogArticle;